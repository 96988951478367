import Vue from 'vue';
import VueRouter from 'vue-router';

// 如果你的组件位于 'src/components/'，确保路径正确
import Home from '@/components/HelloWorld.vue';
import ImageDetails from '@/components/ImageDetails.vue';
import AiNews from '@/components/AiNews.vue';
import ComfyUi from '@/components/ComfyUi.vue';
import SdAi from '@/components/SdAi.vue';
import SdLogin from '@/components/SdLogin.vue';
import store from '@/store'; // If it's inside a store folder under src/
import { getCookie } from '@/utils/cookieUtils';
import ComfyUiNt from '@/components/ComfyUiNt.vue';
import InboundTravel from '@/components/InboundTravel.vue';
import TravelTwo from '@/components/TravelTwo.vue';


// 告诉 Vue 使用 vue-router 路由机制
Vue.use(VueRouter);

// 路由配置
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { title: 'Ai导航站' }
    },
    {
        path: '/image/:id',
        name: 'image-details',
        component: ImageDetails
    },
    {
        path: '/ainews',
        name: 'ai-news',
        component: AiNews
    },
    {
        path: '/prompt',
        name: 'prompt',
        component: Home
    },
    {
        path: '/ComfyUi',
        name: 'ComfyUi',
        component: ComfyUi,
        meta: {
            requiresAuth: true  // 表明访问此路由需要认证
        }
    },
    {
        path: '/SdAi',
        name: 'SdAi',
        component: SdAi,
        meta: {
            requiresAuth: true  // 表明访问此路由需要认证
        }
    },
    {
        path: '/ComfyUiNt',
        name: 'ComfyUiNt',
        component: ComfyUiNt,
        meta: {
            requiresAuth: true  // 表明访问此路由需要认证
        }
    },
    {
        path: '/SdLogin',
        name: 'SdLogin',
        component: SdLogin
    },
    {
        path: '/InboundTravel',
        name: 'InboundTravel',
        component: InboundTravel
    },
    {
        path: '/TravelTwo',
        name: 'TravelTwo',
        component: TravelTwo,
        props: true,
    },
    // ...其他路由
];

// 创建 router 实例
const router = new VueRouter({
    mode: 'history',
    routes
});


router.beforeEach((to, from, next) => {
    // console.log('Route to:', to.path, 'from:', from.path);
    // console.log('Authenticated:', isLoggedIn());

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    document.title = nearestWithTitle ? nearestWithTitle.meta.title : 'Ai导航站';

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isLoggedIn()) {
            // console.log('Redirecting to login...');
            next({ path: '/SdLogin', query: { redirect: to.fullPath } });
        } else {
            // console.log('Access granted to:', to.path);
            next();
        }
    } else {
        // console.log('No authentication needed for:', to.path);
        next();
    }
});
function isLoggedIn() {
    // 检查 Vuex state 是否认为用户已认证
    const authStatus = store.state.isAuthenticated;

    // 可选：同时检查 localStorage 中是否有 token
    // const token = localStorage.getItem('authToken');
    // 检查 localStorage 和 Cookies 中是否有 token
    const token = localStorage.getItem('authToken') || getCookie('authToken');

    return authStatus && token != null;
}


export default router;