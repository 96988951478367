<template>
  <div class="login-container">
    <h1>登录</h1>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <div class="input-group">
          <label for="phoneNumber">手机号:</label>
          <input type="text" id="phoneNumber" v-model="phoneNumber" placeholder="请输入手机号" @input="validatePhoneNumber" pattern="\d{11}" required>
        </div>

        <div class="input-group">
          <label for="verificationCode">验证码:</label>
          <input type="text" id="verificationCode" v-model="verificationCode" placeholder="输入验证码" @input="validateVerificationCode" pattern="\d{6}" required>
          <button @click="getVerificationCode" :disabled="remainingTime > 0" class="verification-button">获取验证码</button>
          <p v-if="remainingTime > 0" class="timer-text">{{ remainingTime }}秒后重新获取</p>
        </div>

      </div>

      <!-- 自定义弹窗 -->
      <div v-if="showModal" class="custom-modal" @click="closeModal">
        <div class="custom-modal-content" @click.stop>
          <span class="custom-modal-close" @click="closeModal">&times;</span>
          <p>{{ verificationMessage }}</p>
        </div>
      </div>

      <button type="submit" class="login-button" :disabled="!verificationCode">登录</button>
      <p>若未注册，自动创建新账户</p>
    </form>
  </div>
</template>

<script>

import apiClient from '@/api/apiService'; // 确保路径正确
import { setCookie } from '@/utils/cookieUtils';

export default {
  data() {
    return {
      phoneNumber: '',
      verificationCode: '',
      canSubmit: false,
      remainingTime: null,
      timer: null,
      showModal: false, // 控制弹窗显示的属性
       verificationMessage: '', // 存储要显示在弹窗中的消息
    };
  },
  beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
  },
  methods: {
    //校验验证码是否是6为纯数字
    validateVerificationCode() {
      // 如果出现非数字字符，移除这些字符
      this.verificationCode = this.verificationCode.replace(/\D/g, '');

      // 如果输入的长度超过6位，则截断
      if (this.verificationCode.length > 6) {
        this.verificationCode = this.verificationCode.slice(0, 6);
      }
    },
    //校验手机号是否是11位纯数字
    validatePhoneNumber() {
      // 如果输入的长度超过11位，则截断
      if (this.phoneNumber.length > 11) {
        this.phoneNumber = this.phoneNumber.slice(0, 11);
      }
      // 如果出现非数字字符，移除这些字符
      this.phoneNumber = this.phoneNumber.replace(/\D/g, '');
    },
    closeModal() {
      this.showModal = false;
    },
    getVerificationCode() {
      // 校验手机号是否为空
      if (!this.phoneNumber) {
        this.verificationMessage = `手机号不能为空！`;
        this.showModal = true; // 显示弹窗
        return;
      }

      // 校验手机号是否为11位纯数字
      if (!/^\d{11}$/.test(this.phoneNumber)) {
        this.verificationMessage = `手机号格式有误，请输入11位数字！`;
        this.showModal = true; // 显示弹窗
        return;
      }

      // 启动倒计时
      this.startCountdown();

      const smsRequest = {
        phoneNumber: this.phoneNumber,
      };
      apiClient.sendVerificationCode(smsRequest)
        .then(response => {
          const data = response.data.code;
          if (data === 200) {
            this.verificationMessage = `获取验证码成功！`;
            this.showModal = true; // 显示弹窗
            this.canSubmit = true;
          } else {
            this.verificationMessage = `获取验证码失败，请稍后重试！`;
            this.showModal = true; // 显示弹窗
          }
        })
        .catch(error => {
          console.error('Error:', error);
          this.verificationMessage = 'Failed to send verification code.'; // 设置弹窗消息
          this.showModal = true; // 显示弹窗
        });
    },
    submitForm() {
      const registerUser = {
          phonenumber: this.phoneNumber,
          captcha: this.verificationCode
        };
            apiClient.submitLoginForm(registerUser)
              .then(response => {
                if (response.data.code == 200) { 
                    const data = response.data;
                    if (data.token) {
                      // localStorage.setItem('authToken', data.token);
                      try {
                          localStorage.setItem('authToken', data.token);
                      } catch (e) {
                          // 当 localStorage 不可用时使用Cookies
                          setCookie('authToken', data.token, 7); // 设置7天过期
                      }
                      this.$store.dispatch('authenticateUser', { status: true, token: data.token });
                      this.$router.push(this.$route.query.redirect || '/SdAi'); // 跳转到SdAi页面或根据redirect查询参数跳转
                      return;
                      }
                    }else{
                      this.verificationMessage = response.data.msg;
                      console.log(this.verificationMessage)
                      this.showModal = true; // 显示错误弹窗
                  // 可以选择在这里做其他错误处理操作，比如清除已输入的用户信息等
                      return
                  }
                })
                .catch(() => {
                    this.verificationMessage = '登录失败，未获取到有效的令牌。';
                    this.showModal = true; // 显示错误弹窗
        });
    },
    startCountdown() {
      if (this.timer) return; // 如果计时器已经在运行，则不再执行

      this.remainingTime = 60;
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.remainingTime = 0; // 确保倒计时文本消失
    },
  }
};
</script>

<style scoped>
/* 全局样式设置，使body全屏且使用flex布局 */
body {
  margin: 0;
  height: 100vh; /* 使用视口高度单位，确保body占满整个视口高度 */
  display: flex;
  align-items: flex-start; /* 从顶部开始对齐，而不是居中 */
  justify-content: center; /* 水平居中 */
  background: #f7f7f7; /* 轻微灰色背景，可根据需要调整 */
}

.login-container {
  transform: translateY(50px); /* 将登录框向下移动50px */
  max-width: 500px;
  margin: auto;
  padding: 60px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-group {
  margin-bottom: 20px;
}

.custom-modal {
  position: fixed;    /* 固定定位，使弹窗始终在视窗中间 */
  left: 0;
  top: 0;
  width: 100%;        /* 覆盖整个视窗宽度 */
  height: 100%;       /* 覆盖整个视窗高度 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  display: flex;      /* 使用Flexbox进行居中 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  z-index: 1000;      /* 高层级确保覆盖其他内容 */
}

.custom-modal-content {
  background-color: #fff;  /* 白色背景 */
  padding: 20px;           /* 内边距 */
  border-radius: 5px;      /* 边角圆润 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  width: 90%;              /* 内容宽度，可根据需求调整 */
  max-width: 500px;        /* 最大宽度，确保不会过宽 */
}

.custom-modal-close {
  float: right;            /* 关闭按钮右对齐 */
  font-size: 28px;         /* 图标大小 */
  font-weight: bold;
  cursor: pointer;         /* 鼠标悬停时显示指针 */
}

.custom-modal-close:hover {
  color: #f00;             /* 悬停时变红色 */
}

.verification-group {
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中对齐输入框和按钮 */
}

.verification-group input[type="text"] {
  flex-grow: 1; /* 输入框占据剩余空间 */
  margin-right: 10px; /* 输入框和按钮之间的间隔 */
}

.get-code-button {
  white-space: nowrap; /* 防止按钮文字换行 */
}

.timer-text {
  margin-left: 10px; /* 与按钮之间的间隔 */
}
.form-group {
  display: flex;
  flex-direction: column; /* 让子元素纵向排列 */
}

.input-group {
  margin-bottom: 20px; /* 为输入框组添加间隔 */
}

.input-group label {
  display: block; /* 让标签占据整行 */
}

.input-group input[type="text"] {
  width: 100%; /* 输入框宽度 */
  margin-bottom: 10px; /* 输入框与下一个元素的间隔 */
}

.verification-button {
  padding: 10px 15px; /* 按钮内边距 */
  background-color: #007bff; /* 按钮背景色 */
  color: white; /* 文字颜色 */
  border: none; /* 无边框 */
  border-radius: 4px; /* 边框圆角 */
  cursor: pointer; /* 鼠标悬停时显示手形指针 */
  margin-bottom: 10px; /* 按钮与下一个元素的间隔 */
}

.verification-button:disabled {
  background-color: #cccccc; /* 禁用状态的按钮背景色 */
  cursor: not-allowed; /* 禁用状态的鼠标指针 */
}

.timer-text {
  color: #555; /* 倒计时文本颜色 */
  margin-bottom: 10px; /* 文本与下一个元素的间隔 */
}


/* ...其他样式... */

.login-button {
  padding: 10px 20px; /* 增加内边距使按钮更易点击 */
  background-color: #28a745; /* 设置一个醒目的背景色 */
  color: white; /* 设置文字颜色为白色 */
  border: 2px solid transparent; /* 设置边框为透明 */
  border-radius: 4px; /* 轻微的圆角边缘 */
  font-weight: bold; /* 加粗字体 */
  cursor: pointer; /* 鼠标悬停时显示手形指针 */
  transition: background-color 0.2s, border-color 0.2s; /* 平滑的颜色过渡效果 */
}

.login-button:hover {
  background-color: #218838; /* 悬停时的背景色变深 */
  border-color: #1e7e34; /* 悬停时的边框色变深 */
}

.login-button:disabled {
  background-color: #cccccc; /* 禁用状态的按钮背景色 */
  cursor: not-allowed; /* 禁用状态的鼠标指针 */
}

</style>
