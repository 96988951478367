import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex'; // Make sure Vuex is imported
import router from './router/router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatDate } from './utils/filters';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vuex); // This might be redundant if Vuex is properly initialized in store.js

Vue.filter('formatDate', formatDate);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
