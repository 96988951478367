<template>
  <div class="details-container">
    <!-- <div class="ai-news-top-left">
      <el-button type="primary" plain @click="goToAiNews">AI资讯</el-button>
      <el-button type="primary" plain @click="showQRCodeModal">扫描加AI交流群</el-button>
      <el-button type="primary" plain @click="fetchData">Prompt词典</el-button>
      <hr /> 
    </div> -->
    <!-- 返回按钮 -->
    <div class="back-button-container">
      <el-button type="primary" plain class="back-button" @click="goBack">返回</el-button>
    </div>
    <div class="content">
      <div class="image-container" v-if="image">
        <!-- 图像将在这里显示 -->
        <img :src="image.imageUrl" :alt="image.prompt" class="details-image" />
      </div>
      <div v-else>
        加载中...
      </div>
      <div class="info-container">
        <!-- 图像的详细信息将在这里显示 -->
        <div>
          <textarea readonly rows="10" cols="40" v-text="prompt" class="textbox"></textarea>
        </div>
        <div v-if="remake && remake.length > 0" style="display: flex; justify-content: center; align-items: center; margin: 20px 0">
          <div style="background-color: grey; border-radius: 10px; padding: 10px; text-align: left; color: white; width: 415px; word-wrap: break-word;">
            备注: {{ remake }}
          </div>
        </div>
        <!-- 更多的图片信息，如模型使用、提示类别等 -->
        <div class="button-group">
          <a :href="aimaginateUrl" target="_blank">
            <el-button type="primary">立即创作</el-button>
          </a>
          <el-button type="primary" @click="copyToClipboard">复制</el-button>
        </div>
      </div>
    </div>
    <!-- 更多图片的缩略图 -->
    <div class="thumbnails">
      <!-- 缩略图列表 -->
    </div>
  </div>
</template>

<script>
import api from '@/api/apiService.js';
export default {
  data() {
    return {
      image: '',
      prompt: '',
      remake: '',
      aimaginateUrl: 'https://aimaginate.chat/#/?from=dh', // 要跳转的URL
      // aimaginateUrl: null
    };
  },
  created() {
    // console.log(this.$route.params);
    this.fetchImageData();
  },
  methods: {
    copyToClipboard() {
      if (navigator.clipboard) {
        // 使用 Clipboard API 来复制文本
        navigator.clipboard.writeText(this.prompt)
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      } else {
        // 如果 Clipboard API 不可用，回退到较旧的方法
        // 创建一个文本域元素
        const textArea = document.createElement('textarea');
        // 设置文本域的内容为需要复制的文本
        textArea.value = this.prompt;
        // 将文本域添加到 DOM 中，以便可以执行复制操作
        document.body.appendChild(textArea);
        // 选择文本域中的内容
        textArea.select();
        // 执行复制操作
        try {
          const successful = document.execCommand('copy');
          const msg = successful ? 'successful' : 'unsuccessful';
          console.log(`Fallback: Copying text command was ${msg}`);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        // 移除文本域元素
        document.body.removeChild(textArea);
      }
    },
    goToAimaginate() {
      window.open(this.aimaginateUrl, '_blank');
    },
    fetchImageData() {
      // 获取路由参数中的 ID
      const id = this.$route.params.id;
      // 调用后端接口获取数据
      // axios.get(`http://localhost:8090/creative/inspiration/${imageId}`)
      api.getImageDetails(id)  
        .then(response => {
          // 假设后端返回的数据结构是 { data: { imageUrl: '...', prompt: '...' } }
          this.image = response.data.data;
          this.prompt = this.image.prompt;
          this.remake =this.image.remake;
        })
        .catch(error => {
          console.error('Error fetching image details:', error);
          // 这里你可以处理错误，比如显示一个错误信息
        });
    },
    goBack() {
      // 使用 router 的 go 方法返回上一页
      this.$router.go(-1);
      // 或者使用 push 方法返回主页
      // this.$router.push('/');
    }
  },
};
</script>

<style scoped>

/* @media (max-width: 600px) {
  .button-container {
    justify-content: flex-start; 
  }
} */
/* 按钮div */
.back-button-container{
  margin-top: 10px; /* 与上面元素的距离 */
}
.back-button{
  margin-left: 10px; /* 或你期望的左边距 */
  margin-top: 10px; /* 与上面元素的距离 */
}

.content {
  padding-left: 0; /* 确保父容器没有额外的内边距 */
  padding-top: 0; /* 确保父容器顶部没有额外的内边距 */
   display: flex;
}

.details-container {
  position: relative;
  padding-top: 50px; /* 确保返回按钮有足够空间 */
}

.image-container {
  width: 800px; /* 容器宽度 */
  height: 600px; /* 容器高度 */
  overflow: hidden; /* 如果图片比容器大，则隐藏超出部分 */
  margin-left: 20px; /* 或你期望的左边距 */
  margin-top: 10px; /* 与上面元素的距离 */
  /* 其他样式保持不变 */
}

.details-image {
  width: 100%; /* 图片宽度完全填充容器 */
  height: 100%; /* 图片高度完全填充容器 */
  object-fit: contain; /* 保持图片原始长宽比 */
  background-color: #f0f0f0; /* 可选，为图片周围可能的空白区域设置背景色 */
}


.info-container {
  flex: 4; /* 占据 4 的比例 */
  /* 可以添加更多样式，比如内边距、边框等 */
  display: flex;
  flex-direction: column; /* 子元素垂直排列 */
  align-items: center; /* 垂直居中子元素 */
}

@media (max-width: 600px) {
  .content {
    flex-direction: column;
  }

  .image-container,
  .info-container {
    flex: none; /* 移除比例，让它们各自占满容器宽度 */
  }
}

/**缩略 */
.thumbnails {
  margin-top: 20px; /* 或适当的间距 */
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 缩略图之间的间距 */
}

.thumbnails img {
  width: calc(33.333% - 10px); /* 三列布局，减去间距 */
  height: auto;
  cursor: pointer;
  /* 其他样式 */
}
/**文本框 */
.textbox {
  resize: none; /* 禁止调整大小 */
  width: auto; /* 或者指定一个固定的宽度 */
  height: auto; /* 或者指定一个固定的高度 */
  /* 可以添加更多样式，如边框、内边距等 */
  font-size: 20px; /* 设置所需的字体大小 */
}

/**按钮容器 */
.button-group {
  display: flex;
  justify-content: space-between; /* 若需要按钮间有间隔 */
  margin-top: 10px; /* 根据需要调整，以在文本框下方留出空间 */
}
.button-group a:first-child .el-button,
.button-group .el-button:first-child { /* 选择器取决于实际的 DOM 结构 */
  margin-right: 20px; /* 设置第一个按钮的右边距 */
}
.button-group .el-button:nth-child(2) { /* 第二个按钮 */
  margin-left: 20px; /* 设置第二个按钮的左边距 */
}

/* 根据需要添加更多样式 */
</style>
