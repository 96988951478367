<template>
  <div class="full-page-container">
    <div class="left-section">
      <form @submit.prevent="sendRequest">
        <div class="form-group mb-3">
          <label for="promptInput" class="form-label">Prompt</label>
          <textarea
            class="form-control"
            id="promptInput"
            v-model="prompt"
            placeholder="请输入描述，请勿输入违禁词，否则可能导致生成出错。"
            rows="4">
          </textarea>
        </div>
        <div class="prompt-container">
          <p>可以试试下列提示词：</p>
          <div v-for="(prompt, index) in prompts" :key="index" class="prompt" @click="setPrompt(prompt)">
            {{ prompt }}
          </div>
        </div>



        <!-- <div class="form-group">
          <label for="reverseInput" class="form-label">不希望出现的内容</label>
          <input type="text" class="form-control" id="reverseInput" v-model="reversePrompt" placeholder="Enter reverse prompt">
        </div>

        <div class="form-group">
            <label for="modelSelect" class="form-label">模型</label>
            <select class="form-control" id="modelSelect" v-model="selectedModel">
                <option value="sd3">sd3</option>
                <option value="sd3-turbo">sd3-turbo</option>
            </select>
        </div> -->


        <!-- <div class="form-group">
          <label for="imageUpload" class="form-label">参考图片</label>
          <input type="file" class="form-control" id="imageUpload" @change="previewImage">
          <div v-if="imageSrc" class="image-preview">
            <img :src="imageSrc" alt="Image preview">
          </div>
        </div> -->

        <button type="submit" class="btn btn-primary" :disabled="isLoading">生成</button>
      </form>
    </div>

        <!-- Right Section -->
    <div class="right-section">
      <div class="header-right">
        <button class="btn" @click="getTaskList">历史任务</button>
      </div>
      <div v-if="showTaskDropdown" class="modal-overlay" @click="toggleDropdown">
        <div class="task-table" @click.stop> <!-- 停止点击事件冒泡 -->
          <table>
            <thead>
              <tr>
                <th>任务中心</th>
                <th>类型</th>
                <th>创建时间</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="task in paginatedTasks" :key="task.id">
                <td>{{ task.id }}</td>
                <td>{{ task.prompt }}</td>
                <td>{{ task.createTime }}</td>
                <td>
                  <button @click="viewTask(task.resultUrl)">查看</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-controls">
            <button @click="previousPage" :disabled="currentPage <= 1">上一页</button>
            <button @click="nextPage" :disabled="currentPage >= totalPages">下一页</button>
          </div>
        </div>
      </div>
      
      <!-- Upper Div -->
      <div class="upper-div">
        <div class="image-size">
          <img v-if="imageUrl && !isLoading" :src="imageUrl" alt="Generated Image" class="image-preview">
        </div>
        <div v-show="isLoading" class="loading">正在生成...</div> <!-- 使用v-show -->
      </div>

      <!-- Lower Div -->
      <div class="lower-div" v-if="imageUrl"> <!-- Only show if imageUrl is set -->
        <button class="btn" @click="shareImage">分享</button>
        <button class="btn" @click="downloadImage">下载</button>
      </div>

      <!-- 错误信息显示 -->
      <div v-if="showErrorModal" class="modal-error-overlay" @click.self="closeErrorModal">
        <div class="modal-error">
          <header class="modal-error-header">
            <button class="modal-error-close" @click="closeErrorModal">&times;</button>
          </header>
          <div class="modal-error-body">
            <p>{{ errorMsg }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 用户信息显示 -->
    <div id="user-info" v-if="userInfo">
      <p class="user-name">用户名: {{ userInfo.userName }}</p>
      <button @click="logout" class="logout-button">退出</button>
    </div>
  </div>
</template>

<script>
import api from '@/api/apiService.js';
import { eraseCookie } from '@/utils/cookieUtils'; // 导入清除cookie的函数

export default {
  data() {
    return {
      showTaskDropdown: false, // 控制下拉选项框显示的状态
      prompt: '',
      reversePrompt: '',
      selectedModel: '',
      imageFile: null,
      imageSrc: null,
      //返回的url
      imageUrl: '', 
      tasks: [],
      currentPage: 1,
      pageSize: 5, // 每页显示的任务数量
       // 其他数据属性...
      isLoading: false, // 用于跟踪图片是否正在生成
      userInfo: null,
      showErrorModal: false,
      errorMsg: null,
      prompts: [
        "Awesome artwork of a wizard on the top of a mountain, he's creating the big text 'AIXWZ' with magic, magic text, at dawn, sunrise",
        "Photo realistic of a mario talking to the smurf on the roof top"
      ]
    };
  },
  computed: {
    paginatedTasks() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.tasks.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.tasks.length / this.pageSize);
    }
  },
  watch: {
        selectedModel(newModel, oldModel) {
            console.log(`模型从 ${oldModel} 改变为 ${newModel}`);
        }
  },
  created() {
    // 组件创建后立即获取用户信息
    this.getUserInfo();
  },
  // mounted() {
  //   this.fetchTasks();
  // },
  methods: {
    toggleDropdown() {
      this.showTaskDropdown = !this.showTaskDropdown;
    },
    // 点击复制prompt
    setPrompt(text) {
      this.prompt = text; // Update the textarea with the selected prompt
    },
    // 关闭错误弹窗信息
    closeErrorModal() {
      this.showErrorModal = false;
    },
    // 展示错误弹窗信息
    showError(message) {
      this.errorMsg = message;
      this.showErrorModal = true;
    },
    //用户退出
    logout() {
      this.$store.commit('setLoading', true); // 假设有一个loading状态在Vuex
      api.logout() // 假设你的退出API URL是'/logout'
        .then(() => {
          localStorage.removeItem('authToken'); // 清除localStorage中的token
          eraseCookie('authToken'); // 清除cookie中的token
          this.$store.commit('setAuthStatus', false); // 更新Vuex状态
          this.$router.push({ name: 'SdLogin' }); // 重定向到登录页面
          this.$store.commit('setLoading', false); // 关闭加载指示器
        })
        .catch(error => {
          console.error('Logout failed:', error);
          this.$store.commit('setLoading', false); // 关闭加载指示器
          this.$store.commit('setError', 'Logout failed. Please try again.'); // 设置错误消息
        });
    },
    getUserInfo() { 
      api.getUserInfo().then(response => {
        this.userInfo = response.data.user;
        // console.log(this.userInfo.userName)
      }).catch(error => {
        console.error('请先登录', error);
      })
    },
    //分享
    shareImage() {
      if (!this.imageUrl) {
        this.errorMsg = '没有图片可分享';
        this.showErrorModal = true;
        return;
      }

      // 使用navigator.clipboard.writeText复制文本到剪贴板
      navigator.clipboard.writeText(this.imageUrl)
        .then(() => {
          this.errorMsg = '图片链接已复制到剪贴板';
          this.showErrorModal = true;
        })
        .catch(err => {
          console.error('复制失败:', err);
          this.errorMsg = '复制图片链接失败';
          this.showErrorModal = true;
        });
    },
    //图片下载
    downloadImage() {
        if (!this.imageUrl) {
          alert('');
          this.errorMsg = '没有图片可下载';
          this.showErrorModal = true;
          return;
        }

        // 生成随机文件名，基于时间戳和随机数
        const timestamp = new Date().getTime(); // 获取当前时间的时间戳
        const randomNum = Math.floor(Math.random() * 1000); // 生成一个0到999的随机数
        const filename = `image_${timestamp}_${randomNum}.jpg`; // 构造文件名

        // 创建一个a标签元素用于下载
        const downloadLink = document.createElement('a');
        downloadLink.href = this.imageUrl; // 这里使用图片的URL
        downloadLink.download = filename; // 设置下载的文件名为随机生成的名字
        document.body.appendChild(downloadLink); // 添加到页面中
        downloadLink.click(); // 触发点击进行下载
        document.body.removeChild(downloadLink); // 下载后从页面移除这个元素
      },
     previewImage(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.imageFile = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imageFile = null;
        this.imageSrc = null;
      }
    },

    sendRequest() {
      //生图  先将展示的图片清除
      this.imageUrl = '';

      if (!this.prompt) {
        this.errorMsg = 'Prompt 不能为空';
        this.showErrorModal = true;
        return;
      }

      this.isLoading = true;  // 这里添加这一行
      const requestImage = {
        prompt: this.prompt,
        model: this.selectedModel,
        reversePrompt: this.reversePrompt,
        imageUrl: this.imageUrl,
      };
      api.sendImageCreationRequest(requestImage)
        .then(response => {
          // 检查响应码
          if (response.data.code === 200) {
            // 如果请求成功，处理图片URL
            this.imageUrl = response.data.msg; // 假设成功的消息就是图片URL
            this.isLoading = false;
          } else if (response.data.code === 500) {
            // 如果请求失败，显示错误信息
            this.errorMsg = response.data.msg; // 错误信息存储
            this.showErrorModal = true; // 显示错误模态框
            this.isLoading = false; // 停止加载状态
          }
          // 如果还有其他特殊的code处理，可以继续添加else if块
        })
        .catch(error => {
          // 捕获网络或其他错误
          console.error('Error:', error);
          this.errorMsg = '生成错误，请重试。'; // 设置一个更通用的错误消息
          this.showErrorModal = true;
          this.isLoading = false;
        });
    },
    getTaskList() {
      api.getTaskList().then(response => { 
        this.tasks = response.data.data;
        this.toggleDropdown();  // 打开下拉菜单
      }).catch(error => {
        console.error("Failed to fetch tasks:", error);
      });
    },

    viewTask(url) {
      window.open(url, '_blank'); // 在新标签页中打开任务链接
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  }
};
</script>

<style scoped>
.full-page-container {
  display: flex;
  width: 100%;
  height: 90vh; /* Full viewport height */
  border: 1px solid black; /* Black border around the container */
}

.left-section, .right-section {
  padding: 20px;
}

.left-section {
  width: 30%;
  border-right: 1px solid #ddd;
}

.right-section {
  width: 70%;
}

.image-preview{
  width: 100%; /* 图片宽度自动调整为容器宽度 */
  height: 100%; /* 图片高度自动调整为容器高度 */
  border-radius: 8px; /* 图片圆角边框 */
  object-fit: contain; /* 图片等比例缩放填充 */
}
.image-size {
  width: 300px; /* 容器的固定宽度 */
  height: 400px; /* 容器的固定高度 */
  display: flex;
  justify-content: center; /* 水平居中显示图片 */
  align-items: center; /* 垂直居中显示图片 */
}

.loading {
    width: 100%; /* 加载状态占满整个div宽度 */
    text-align: center; /* 加载状态文字居中 */
    padding: 20px; /* 加载状态内边距 */
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  align-self: flex-start; /* Aligns label to the top-left */
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px; /* 边距/
  gap: 20px; /* This creates space between the inner divs */
  height: 100%; /* Adjust height as needed */
  border: 1px solid black; /* Adds border around the right section */
  background-color: #fff; /* Default white background for the right section */
}

.upper-div {
  display: flex;
  position: relative; /* 确保div是相对定位，如果需要内部元素定位 */
  text-align: center; /* 使图片和加载文本居中显示 */
  flex-grow: 6; /* Takes 60% of the space */
  align-items: center; /* Vertically center content */
  border: 1px solid black; /* Adds border around the upper div */
  background-color: inherit; /* Inherits the background color from the parent */
  overflow: hidden; /* Prevents the image from spilling out */
  flex-direction: column; /* 让内容垂直堆叠 */
  justify-content: center; /* 内容水平居中 */
  min-height: 300px; /* 确保有足够的高度显示内容 */
  margin-top: 20px; /* 距离上方元素的间距 */
  padding: 30px;
}
.upper-div > div {
  width: 100%; /* 子div占满upper-div的宽度 */
  display: flex;
  justify-content: center; /* 子div内的内容水平居中 */
  align-items: center; /* 子div内的内容垂直居中 */
  margin-top: 30px; /* 与上方元素的间距 */
  margin-bottom: 30px; /* 与下方元素的间距 */
}

.lower-div {
  flex-grow: 3; /* Takes 30% of the space */
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  background-color: inherit; /* Inherits the background color from the parent */
}

.lower-div {
  text-align: center; /* Centers buttons horizontally */
  padding: 20px; /* Padding around the buttons */
}

.btn {
  margin: 0 15px; /* Spacing between buttons */
  padding: 10px 20px; /* 内边距调整，确保按钮看起来更加填充 */
  font-size: 16px; /* 文本大小 */
  color: white; /* 文本颜色 */
  background-color: #3498db; /* 蓝色背景 */
  border: 2px solid #3498db; /* 蓝色边框 */
  border-radius: 5px; /* 圆角边框 */
  cursor: pointer; /* 悬停时显示手形光标 */
  transition: background-color 0.3s; /* 背景颜色变化的过渡效果 */
  outline: none; /* 移除聚焦时的边框 */
}

.btn:hover {
  background-color: #2980b9; /* 悬停时背景色变深 */
}

.right-section {
  position: relative;
  /* 其他样式 */
}

.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #4CAF50;
  color: white;
}

/* 确保按钮在 div 的右上角 */
.header-right {
  position: absolute;
  top: 10px; /* 根据需要调整 */
  right: 10px; /* 根据需要调整 */
}

/* username */
#user-info {
  position: absolute; /* 或者使用fixed根据需要 */
  top: 20px;
  right: 20px;
  display: flex; /* 添加Flexbox布局 */
  align-items: center; /* 垂直居中对齐元素 */
}

#user-info .user-name,
#user-info .logout-button {
  margin: 0; /* 移除默认的margin，特别是对于<p>元素 */
}

#user-info .logout-button {
  margin-left: 10px; /* 在用户名和退出按钮之间添加一些空间 */
  padding: 5px 10px; /* 按钮的内边距 */
  /* 为按钮添加更多样式，如果需要的话 */
}

#user-info .logout-button {
  padding: 6px 12px; /* 按钮的内边距，足够的空间使其易于点击 */
  font-size: 14px; /* 文字大小 */
  text-transform: uppercase; /* 文字大写 */
  letter-spacing: 0.05em; /* 字符间距稍微增加 */
  color: #fff; /* 白色文字 */
  background-color: #d9534f; /* 深红色背景，常用于警告或退出按钮 */
  border: none; /* 无边框 */
  border-radius: 4px; /* 圆角边框 */
  cursor: pointer; /* 鼠标悬停时显示手形指针 */
  transition: background-color 0.2s ease-in-out; /* 平滑背景色过渡 */
  outline: none; /* 移除聚焦时的轮廓 */
}

#user-info .logout-button:hover {
  background-color: #c9302c; /* 鼠标悬停时背景色变深 */
}

#user-info .logout-button:disabled {
  background-color: #cccccc; /* 禁用状态的按钮背景色 */
  cursor: not-allowed; /* 禁用状态的鼠标指针 */
}


/* 错误信息弹窗 */
.modal-error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-error {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 50%; /* 根据需要调整宽度 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-error-header {
  display: flex;
  justify-content: flex-end;
}
.modal-error-close {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #aaa;
}

.modal-error-close:hover {
  color: #f00;
}

.modal-error-body {
  padding: 10px 0;
}


.prompt-container {
    text-align: left; /* 确保容器内所有文本都左对齐 */
    margin-top: 30px; /* 与上方元素间距30px */
    margin-bottom: 30px; /* 与下方元素间距30px */
}

.prompt {
    margin: 10px 0; /* 给每个提示词之间添加一些垂直间距 */
    font-style: italic; /* 可选: 如果需要可以给提示词添加斜体样式，突出显示 */
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0; /* Slight background for better visibility */
    border-radius: 5px;
}

.prompt:hover {
  background-color: #e0e0e0; /* Lighter background on hover */
}


table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #4CAF50;
  color: white;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色遮罩层 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.task-table {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 60%; /* 固定宽度 */
  max-height: 80%; /* 固定高度 */
  overflow: auto; /* 超出内容可滚动 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #4CAF50;
  color: white;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


</style>
