import axios from 'axios';
import router from '../router/router'; // update the path based on your project structure
import store from '../store'; 
import { getCookie, eraseCookie } from '@/utils/cookieUtils';


const apiClient = axios.create({
    baseURL: 'https://www.aidaohangzhan.com/api',
    // baseURL: 'http://localhost:6060/',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});
// //每次请求携带token
// apiClient.interceptors.request.use(
//     config => {
//         // 从localStorage或其他存储方式中获取token
//         // const token = localStorage.getItem('authToken')
//         const token = localStorage.getItem('authToken') || getCookie('authToken');

//         if (token) {
//             // 如果token存在，则在每个请求头中添加token。这里的'Authorization'是示例，你根据后端接受的字段名调整
//             config.headers['Authorization'] = 'Bearer ' + token
//         }

//         return config
//     },
//     error => {
//         return Promise.reject(error)
//     }
// );


// // 添加一个服务器响应拦截器
// apiClient.interceptors.response.use(
//     (response) => {
//         if (response.data.code === 401) {
//             // console.log("Token expired, redirecting to login page");
//             localStorage.removeItem('authToken');
//             store.dispatch('authenticateUser', { status: false, token: null });  // 使用导入的store
//             router.push('/SdLogin'); // 使用导入的router
//             return;
//         }
//         return response;
//     },
//     (error) => {
//         if (error.response.data.code === 401) { // 检查response数据中的code字段
//             // console.log("Token expired, redirecting to login page");
//             localStorage.removeItem('authToken');
//             store.dispatch('authenticateUser', { status: false, token: null });  // 使用导入的store
//             router.push('/SdLogin'); // 使用导入的router
//         }

//         return Promise.reject(error);
//     }
// );

// 每次请求携带token
apiClient.interceptors.request.use(
    config => {
        let token;
        try {
            token = localStorage.getItem('authToken');
        } catch (error) {
            token = getCookie('authToken');
        }

        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 添加一个服务器响应拦截器
apiClient.interceptors.response.use(
    response => {
        if (response.data && response.data.code === 401) {
            // 可能需要根据你的实际数据结构调整检查方式
            try {
                localStorage.removeItem('authToken');
            } catch (e) {
                eraseCookie('authToken');  // 如果localStorage不可用，尝试移除cookie
            }
            store.dispatch('authenticateUser', { status: false, token: null });
            router.push('/SdLogin');
            return Promise.reject(new Error('Unauthorized')); // 返回一个被拒绝的Promise
        }
        return response; // 如果不是401，正常返回响应
    },
    error => {
        if (error.response && error.response.data.code === 401) {
            try {
                localStorage.removeItem('authToken');
            } catch (e) {
                eraseCookie('authToken');  // 如果localStorage不可用，尝试移除cookie
            }
            store.dispatch('authenticateUser', { status: false, token: null });
            router.push('/SdLogin');
        }
        return Promise.reject(error);
    }
);


export default {
    getList() {
        return apiClient.get('/creative/inspiration/getList');
    },
    search(creativeInspiration) {
        return apiClient.post('/creative/inspiration/search', creativeInspiration);
    },
    getImageDetails(id) {
        return apiClient.get(`/creative/inspiration/${id}`);
    },
    getAiNews() {
        return apiClient.get('/creative/news/getAiNewsList');
    },
    getLabelList() { 
        return apiClient.get('/creative/label/list');
    },
    getLabelListByLabelId(id) {
        return apiClient.get(`/creative/news/getById/${id}`);
    },
    sendPrompt(interiorDesign) {
        return apiClient.post('/comfyui/sn', interiorDesign, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.data) // Assuming apiClient is something like axios
            .catch(error => Promise.reject(error.response || error));
    },
    sendNt(interiorDesign) {
        return apiClient.post('/comfyui/nt', interiorDesign, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.data) // Assuming apiClient is something like axios
            .catch(error => Promise.reject(error.response || error));
    },
    getResult(promptId) { 
        return apiClient.get(`/comfyui/get/${promptId}`);
    },
    uploadImage(formData, type) {
        // 将类型作为URL参数传递
        const url = `/comfyui/upload/${type}`;

        // 发送POST请求
        return apiClient.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    sendImageCreationRequest(requestImage) {
        return apiClient.post('/sd/create', requestImage, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    sendVerificationCode(smsRequest) {
        return apiClient.post('/sms/send/sendLogin', smsRequest, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    submitLoginForm(registerUser) {
        return apiClient.post('/loginPhong', registerUser, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    getTaskList() {
        return apiClient.get('/user/task/getList');
    },
    getUserInfo() {
        return apiClient.get('/getInfo');
    },
    logout() {
        return apiClient.post('/logout');
    },
    getContext(requestContent) {
        return apiClient.post('/moonshot/content', requestContent,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    optimizeContext(requestContent) {
        return apiClient.post('/moonshot/optimize', requestContent, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    getUrl(requestContent) {
        return apiClient.post('/moonshot/getUrl', requestContent, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    uploadImageToOCR(file) {
        const formData = new FormData();
        formData.append('file', file);
        return apiClient.post('/moonshot/upload', formData, {
            headers: {
                'Content-Type':'multipart/form-data'
            }
        });
    },
};
