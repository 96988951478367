<template>
  <div class="comfyui-container" style="display: flex; width: 100%;">
    <div class="left-container">
      <div class="options-container">
        <button
          v-for="room in rooms"
          :key="room"
          :class="{ 'active': selectedRoom === room }"
          @click="selectRoom(room)"
        >
          {{ room }}
        </button>
      </div>
      <div class="input-container">
        <div class="upload-area" @dragover.prevent="handleDragOver" @drop.prevent="handleFileDrop('original', $event)">
          <input type="file" @change="uploadFile($event, 'original')" hidden ref="originalInput"/>
          <img v-if="originalImagePreview" :src="originalImagePreview" alt="原图预览" class="image-preview"/>
          <div v-if="isUploadingOriginal" class="loading-spinner"></div>
          <button class="upload-instructions" @click="triggerInputClick('original')">
            拖动原图到这里上传或点击上传
          </button>
        </div>
        <div class="upload-area" @dragover.prevent="handleDragOver" @drop.prevent="handleFileDrop('reference', $event)">
          <input type="file" @change="uploadFile($event, 'reference')" hidden ref="referenceInput"/>
          <img v-if="referenceImagePreview" :src="referenceImagePreview" alt="样式图预览" class="image-preview"/>
          <div v-if="isUploadingReference" class="loading-spinner"></div>
          <button class="upload-instructions" @click="triggerInputClick('reference')">
            拖动样式图到这里上传或点击上传
          </button>
        </div>
      </div>
      <!-- <button class="submit-button" @click="sendRequest">提交生成</button> -->
      <button class="submit-button" @click="sendRequest" :disabled="isSubmitting">提交生成</button>
      <!-- <span v-if="isSubmitting">{{ countdown }}</span> -->

    </div>
    <div class="right-container">
      <!-- Top Image Display Container -->
      <div class="top-guide-container">
          <a href="https://dauwpgpd0tp.feishu.cn/wiki/UQ86wKASrijnORktMA6cMBpzntb?from=from_copylink" target="_blank">室内设计使用指南</a>
      </div>

      <div class="top-image-container" style="position: relative;">
        <img v-if="imageUrls.length > 0" :src="imageUrls[0]" alt="Generated Image" class="generated-image"/>
        <div v-else>{{ getImageMessage }}</div>
        <!-- 只有当有图片时，才显示下载按钮，并且位于右下角 -->
        <div v-if="imageUrls.length > 0" class="download-button-container">
            <button @click="downloadImage" class="download-image">下载</button>
        </div>
      </div>

      <div class="header-right">
        <button class="btn" @click="getTaskList">历史任务</button>
      </div>
      <div v-if="showTaskDropdown" class="modal-overlay" @click="toggleDropdown">
        <div class="task-table" @click.stop> <!-- 停止点击事件冒泡 -->
          <table>
            <thead>
              <tr>
                <th>任务中心</th>
                <th>类型</th>
                <th>创建时间</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="task in paginatedTasks" :key="task.id">
                <td>{{ task.id }}</td>
                <td>{{ task.prompt }}</td>
                <td>{{ task.createTime }}</td>
                <td>
                  <button @click="viewTask(task.resultUrl)">查看</button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-controls">
            <button @click="previousPage" :disabled="currentPage <= 1">上一页</button>
            <button @click="nextPage" :disabled="currentPage >= totalPages">下一页</button>
          </div>
        </div>
      </div>

      <!-- Bottom QR Code Containers -->
      <div class="bottom-qr-containers">
        <div class="qr-container">
          <!-- <textarea>123456789</textarea> -->
          <div class="qr-heading">开发不易请喝杯咖啡呗！</div>
          <img src="/jeff.jpg" alt="QR Code 1" class="qr-code"/>
        </div>
        <div class="qr-container">
          <!-- <textarea>123456789</textarea> -->
          <div class="qr-heading">合作请添加客服企业微信</div>
          <img src="/qiye.jpg" alt="QR Code 2" class="qr-code"/>
        </div>
      </div>
      <!-- 弹窗覆盖层 -->
      <div v-if="showQRModal" class="qr-modal-overlay" @click="closeQRModal">
        <!-- 弹窗内容，阻止点击事件冒泡 -->
        <div class="qr-modal-content" @click.stop>
          <button class="close-gz" @click="showQRModal = false">&times;</button>
          <div class="modal-content">关注获取更多免费生图工具</div>
          <div class="image-container">
            <img src="/gzh.png" alt="QR Code" />
          </div>
        </div>
      </div>
      <!-- 错误信息弹窗覆盖层 -->
      <div v-if="showErrorModal" class="error-modal-overlay" @click="closeErrorModal">
        <!-- 弹窗内容，阻止点击事件冒泡 -->
        <div class="error-modal-content" @click.stop>
          <p>{{ errorMessage }}</p>
          <button @click="showErrorModal = false">关闭</button>
        </div>
      </div>




    </div>
    <div id="user-info" v-if="userInfo">
      <p class="user-name">用户名: {{ userInfo.userName }}</p>
      <button @click="logout" class="logout-button">退出</button>
    </div>
  </div>
</template>

<script>
import api from '@/api/apiService.js';
import { eraseCookie } from '@/utils/cookieUtils'; // 导入清除cookie的函数

export default {
  data() {
    return {
      // Existing properties
    isUploadingOriginal: false,
    isUploadingReference: false,
      getImageMessage: null,
      //是否提交按钮
      isSubmitting: false,
      //弹窗控制
      showQRModal: false,
      //错误信息控制
      showErrorModal: false,
    errorMessage: '',
        originalImage: '',
      referenceImage: '',
        //任务id
      promptId: null,
        //返回的图片集合
        imageUrls: [],
      continuePolling: true, // 控制轮询的标志
        rooms: ['客厅', '卧室', '厨房', '办公室'],
      selectedRoom: '客厅',
      //原图预览图
      originalImagePreview: '',
      //样式图预览图
      referenceImagePreview: '',
      referenceImageKey: 0, // 初始化为0或其他值
      originalImageKey: 0,
      tasks: [],
      isShowModal: false,
      userInfo: null,
      showTaskDropdown: false,
      currentPage: 1,
      pageSize: 5, // 每页显示的任务数量
    };
  },
  created() {
    // 组件创建后立即获取用户信息
    this.getUserInfo();
  },
  computed: {
    paginatedTasks() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.tasks.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.tasks.length / this.pageSize);
    }
  },
    
  methods: {
    //用户退出
    // logout() {
    //   api.logout() // 假设你的退出API URL是'/logout'
    //     .then(() => {
    //       localStorage.removeItem('authToken'); // 清除token
    //       this.$store.commit('setAuthStatus', false); // 更新Vuex状态
    //       this.$router.push({ name: 'SdLogin' }); // 重定向到登录页面
    //     })
    //     .catch(error => {
    //       console.error('Logout failed:', error);
    //     });
    // },
     logout() {
      this.$store.commit('setLoading', true); // 假设有一个loading状态在Vuex
      api.logout() // 假设你的退出API URL是'/logout'
        .then(() => {
          localStorage.removeItem('authToken'); // 清除localStorage中的token
          eraseCookie('authToken'); // 清除cookie中的token
          this.$store.commit('setAuthStatus', false); // 更新Vuex状态
          this.$router.push({ name: 'SdLogin' }); // 重定向到登录页面
          this.$store.commit('setLoading', false); // 关闭加载指示器
        })
        .catch(error => {
          console.error('Logout failed:', error);
          this.$store.commit('setLoading', false); // 关闭加载指示器
          this.$store.commit('setError', 'Logout failed. Please try again.'); // 设置错误消息
        });
    },
    getUserInfo() { 
      api.getUserInfo().then(response => {
        this.userInfo = response.data.user;
        console.log(this.userName)
      }).catch(error => {
        console.error('请先登录', error);
      })
    },
    openModal() {
      this.showQRModal = true; // 显示弹窗
      this.$nextTick(() => {
        document.querySelector('.qr-modal-overlay').classList.add('show');
      });
    },
    // 关闭QR码弹窗
    closeQRModal() {
      this.showQRModal = false;
    },
    // 关闭错误信息弹窗
    closeErrorModal() {
      this.showErrorModal = false;
    },
    closeModal() {
      document.querySelector('.qr-modal-overlay').classList.remove('show');
      setTimeout(() => {
        this.showQRModal = false; // 隐藏弹窗
      }, 300); // 等待动画完成
    },
    selectRoom(room) {
      this.selectedRoom = room;
    },
    sendRequest() {
      //生图  先将展示的图片清除
      this.imageUrls = [];

      if (this.isSubmitting) return;
      this.isSubmitting = true;

      if (!this.originalImage || !this.referenceImage || !this.selectedRoom) {
        this.errorMessage = '所有字段都是必填的，请确保填写了原图、参考图和房间类型。';
        this.showErrorModal = true;
        this.isSubmitting = false; // 重置提交状态
        console.error('Missing required fields:', { originalImage: this.originalImage, referenceImage: this.referenceImage, roomType: this.selectedRoom });
        return; // Stop the function execution
      }
      
      const interiorDesignPayload = {
        originalImage: this.originalImage,
        referenceImage: this.referenceImage,
        roomType: this.selectedRoom,
      };

      api.sendPrompt(interiorDesignPayload)
        .then(response => {
          if (response.code === 200) {
            this.promptId = response.data;
            if (this.promptId) {
              this.continuePolling = true;
              this.getImageMessage = "正在生成图片，请稍后...";
              this.pollForResult();
            }
          } else if (response.code === 500) {
            // 服务器返回错误
            this.errorMessage = response.data.msg || '未知错误，请稍后重试。';
            this.showErrorModal = true;
          }
        })
        .catch(error => {
          this.errorMessage = '请求失败，请稍后重试。';
          this.showErrorModal = true;
          console.error('Error when sending prompt:', error);
        })
        .finally(() => {
          this.isSubmitting = false; // 请求完成后重置提交状态
        });
    },


    handleDragOver(event) {
      event.dataTransfer.dropEffect = 'copy';
    },
    
    pollForResult() {
      const fetchResult = () => {
        if (!this.continuePolling) {
          return; // 停止轮询
        }
        api.getResult(this.promptId).then(response => {
          const result = response.data;
          if (result.code === 500) {
            this.continuePolling = false; // 停止轮询
            this.errorMessage = result.msg;
            this.showErrorModal = true;
            return;
          }
          if (Array.isArray(result.data) && result.data.length > 0) {
            this.continuePolling = false; // 成功获取结果后，停止轮询
            this.imageUrls = result.data;
            this.openModal();
          } else {
            setTimeout(fetchResult, 3000); // 继续轮询
          }
        }).catch(error => {
          console.error('Polling error:', error);
          if (this.continuePolling) {
            setTimeout(fetchResult, 3000); // 继续轮询
          }
        });
      };
      fetchResult();
    },
    handleFileDrop(type, event) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];
        this.uploadFile({ target: { files: [file] } }, type);
      }
    },

    triggerInputClick(type) {
      if (type === 'original') {
        this.$refs.originalInput.click();
      } else if (type === 'reference') {
        this.$refs.referenceInput.click();
      }
    },
    uploadFile(event, type) {
      if (type === 'original') {
        this.isUploadingOriginal = true;
      } else if (type === 'reference') {
        this.isUploadingReference = true;
      }
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        // 文件重命名逻辑
        // 创建一个新的文件名：以英文字符和时间戳组合
        const newFileName = `image-${Date.now()}.${file.name.split('.').pop()}`;

        // 创建FormData并添加文件
        const formData = new FormData();
        // 添加文件时使用新文件名
        formData.append('file', file, newFileName);

        // 调用API进行上传
      api.uploadImage(formData, type).then(response => {
        const result = response.data;
        if (result.code === 200) {
          //处理成功消息
          if (type === 'original') {
            this.originalImage = result.data.fileName; // DataURL for image preview
            // console.log(this.originalImage)
            this.isUploadingOriginal = false;
          } else if (type === 'reference') {
            this.referenceImage = result.data.fileName;
            this.isUploadingReference = false;
          }
          // 生成预览
          const reader = new FileReader();
          reader.onload = (e) => {
            if (type === 'original') {
              // 使用Vue.set确保Vue能检测到这个变化
              this.$set(this, 'originalImagePreview', e.target.result);
              this.originalImageKey = Date.now(); // 更新key以触发重新渲染

            } else if (type === 'reference') {
              // 使用Vue.set确保Vue能检测到这个变化
              this.$set(this, 'referenceImagePreview', e.target.result);
              this.referenceImageKey = Date.now(); // 更新key以触发重新渲染
            }
          };
          reader.readAsDataURL(file);
        } else { 
          this.errorMessage = result.msg;
          this.showErrorModal = true;
          if (type === 'original') {
            this.isUploadingOriginal = false;
          } else if (type === 'reference') {
            this.isUploadingReference = false;
          }
          } 
        }).catch(error => {
          console.error('Upload error:', error);
            if (type === 'original') {
              this.isUploadingOriginal = false;
            } else if (type === 'reference') {
              this.isUploadingReference = false;
            }
        });
    },
    downloadImage() {
      if (this.imageUrls.length > 0) {
        const imageUrl = this.imageUrls[0]; // 获取图片URL
        const imageName = "downloadedImage.jpg"; // 设置下载的图片名称，可以根据需要修改

        // 创建一个临时的a标签来模拟点击下载
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    getTaskList() {
      api.getTaskList().then(response => { 
        this.tasks = response.data.data;
        console.log(this.tasks)
        this.toggleDropdown();  // 打开下拉菜单
      }).catch(error => {
        console.error("Failed to fetch tasks:", error);
      });
    },

    viewTask(resultUrl) {
        // Open a new tab and navigate to the image URL
        window.open(resultUrl, '_blank');
    },

     toggleDropdown() {
      this.showTaskDropdown = !this.showTaskDropdown;
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },

  beforeDestroy() {
    this.continuePolling = false; // 组件销毁前停止轮询
  },
  
}
</script>

<style scoped>
/* Global container styles */
.comfyui-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90vh; /* Full viewport height */
}

/* Shared styles for both left and right containers */
.container {
  padding: 16px;
  border: 2px solid #000;
  box-sizing: border-box;
}
.options-container {
  display: flex;
  width: 300px; /* 宽度设置为300px */
  height: 40px; /* 高度设置为30px */
  align-items: center; /* 垂直居中 */
  overflow: hidden; /* 如果内容太多则隐藏 */
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box; /* 防止边框和内边距扩大容器 */
   margin-bottom: 10px; /* 底部外边距 */
}
.options-container button:not(:last-child) {
  margin-right: calc((100% - 4 * 60px) / 3); /* 假设每个按钮宽60px，并且有3个间隙 */
}

.options-container button,
.submit-button {
  padding: 10px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  background-color: #f0f0f0;
  outline: none;
}

.options-container button.active,
.submit-button {
  background-color: #007bff;
  color: white;
}

/* Styles for input and upload areas */
.input-container input,
.upload-area div {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  overflow: hidden; /* 防止内容溢出容器 */
}
/* Styles for upload areas */
.upload-area {
  border: 2px dashed #ccc;
  width: 300px; /* fixed width */
  height: 230px; /* fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; /* space between the upload areas */
  text-align: center;
  cursor: pointer;
  padding: 10px;
  flex-direction: column;
  overflow: hidden; /* 防止内容溢出容器 */
}
/* Styles for image previews */
.image-preview {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain; /* maintain aspect ratio */
  margin-top: 10px;
  margin-bottom: 10px; /* 调整这个值来增加图片和文字之间的距离 */
}
.upload-instructions {
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 0px; /* 上传指示与图片之间的间隙 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏溢出的文本 */
  text-overflow: ellipsis; /* 溢出的文本显示为省略号 */
  width: 90%; /* 或根据需求调整宽度 */
  box-sizing: border-box; /* 边框和内边距包含在宽度内 */
   height: 35px;
  padding: 5px 10px; /* 内边距 */
  text-align: center; /* 文本居中 */
  text-overflow: ellipsis; /* 长文本显示省略号 */
}


/* Shared style for image display and preview containers */
.image-container {
  text-align: center;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Specific style for image display area */
.image-display {
  width: 100%;
  height: auto; /* Adjust height as needed */
}

.left-container,
.right-container {
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
/* Specific styles for left and right containers */
.left-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  height: 100%; /* 如果你想要整个容器都填满父级元素 */
}


.input-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the children horizontally */
}


.generated-image {
  max-width: 85%; /* Adjust sizing as needed */
  height: auto;
}


.right-container {
  width: 70%; /* or your desired width */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Spaces out the child elements */
  border: 2px solid #ccc; /* Visible border for demonstration */
  padding: 20px; /* Inner spacing */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  align-items: center;
}

.top-image-container {
  flex-grow: 1; /* Allows this container to take up available space */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  margin-bottom: 20px; /* Space below this container */
  max-height: 400px; /* Maximum height */
  border: 2px dashed #ccc; /* Example border */
}
.top-image-container {
  width: 600px;
  text-align: center;
  margin-bottom: 20px; /* Adjust spacing between top image and QR code containers */
  border: 2px solid #ccc; /* Add border */
  padding: 10px; /* Add padding */
}

.qr-heading {
  margin-bottom: 10px;
}

.qr-code {
  width: 100px; /* Fixed width */
  height: 100px; /* Fixed height */
}

.bottom-qr-containers {
  display: flex;
  justify-content: space-around; /* Adjust this if you want the containers to be closer or have specific positioning */
  align-items: center;
  height: 150px;
  gap: 0px; /* Sets the gap between flex items to 0 */
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #ccc;
  padding: 10px;
  margin: 10px; /* Ensure no additional margin is causing space */
}

.qr-modal-overlay {
  position: fixed; /* 使用fixed定位，使其脱离文档流并固定于视口 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex; /* 使用flex布局使子元素居中 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  z-index: 1000; /* 确保弹窗在页面上的其他内容之上 */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.qr-modal-content {
  position: relative;
  background-color: white; /* 设置背景颜色为白色 */
  color: black; /* 设置文本颜色为黑色 */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  text-align: center;
  width: 600px; /* 可根据需要调整宽度 */
}

.qr-modal {
  background-color: white; /* 弹窗背景色 */
  padding: 20px; /* 弹窗内边距 */
  border-radius: 5px; /* 弹窗边框圆角 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 弹窗阴影效果 */
  max-width: 500px; /* 弹窗最大宽度，根据需要调整 */
  z-index: 1001; /* 确保弹窗内容在背景之上 */
}

.qr-modal-overlay.show {
  opacity: 1;
  visibility: visible;
}

.error-modal-overlay {
  position: fixed; /* 使用fixed定位，使得覆盖层固定在视口中 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  z-index: 1000; /* 确保覆盖层位于顶层 */
}

.error-modal-overlay p {
  background-color: white;
  padding: 20px;
  border-radius: 10px; /* 圆角边框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  max-width: 80%; /* 限制最大宽度，以适应不同屏幕尺寸 */
  text-align: center; /* 文本居中 */
}

.top-image-container {
    position: relative;
    /* 其他样式 */
}

.download-button-container {
    position: absolute;
    right: 0;
    bottom: 0;
}

.generated-image {
    width: 85%; /* 或其他宽度 */
    /* 其他样式 */
}
/**右上角样式 */
.top-guide-container {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px; /* 或根据需要调整 */
    z-index: 10; /* 确保它在其他内容上方 */
}
/**右上角样式 */
.top-guide-container a {
    color: #007bff; /* 链接颜色 */
    text-decoration: none; /* 去除下划线 */
    font-weight: bold; /* 字体加粗 */
}

.right-container {
  position: relative;
  /* 其他样式保持不变 */
}

.download-image {
  border: none; /* 移除边框 */
  background-color: transparent; /* 背景透明 */
  color: blue; /* 设置字体颜色为蓝色 */
  cursor: pointer; /* 鼠标悬停时显示指针 */
  padding: 10px 20px; /* 添加一些内边距以增加可点击区域 */
  text-decoration: underline; /* 下划线表示可点击 */
  font-size: 16px; /* 字体大小 */
  outline: none; /* 移除焦点时的轮廓线 */
}
.modal-content {
  position: absolute;
  top: -40px; /* 将文本框放在图片的上方 */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微的阴影效果 */
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  width: 100%;
  text-align: center;
  margin: 10px auto; /* 居中并与下方图片保持间隔 */
}

.loading-spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 确保按钮在 div 的右上角 */
.header-right {
  position: absolute;
  top: 10px; /* 根据需要调整 */
  right: 10px; /* 根据需要调整 */
}


.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #4CAF50;
  color: white;
}

.close-gz {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #aaa;
  cursor: pointer;
}

.close-gz:hover {
  color: black;
}

/* 确保按钮在 div 的右上角 */
.header-right {
  position: absolute;
  top: 10px; /* 根据需要调整 */
  right: 10px; /* 根据需要调整 */
}

/* username */
#user-info {
  position: absolute; /* 或者使用fixed根据需要 */
  top: 20px;
  right: 20px;
  display: flex; /* 添加Flexbox布局 */
  align-items: center; /* 垂直居中对齐元素 */
}

#user-info .user-name,
#user-info .logout-button {
  margin: 0; /* 移除默认的margin，特别是对于<p>元素 */
}

#user-info .logout-button {
  margin-left: 10px; /* 在用户名和退出按钮之间添加一些空间 */
  padding: 5px 10px; /* 按钮的内边距 */
  /* 为按钮添加更多样式，如果需要的话 */
}

#user-info .logout-button {
  padding: 6px 12px; /* 按钮的内边距，足够的空间使其易于点击 */
  font-size: 14px; /* 文字大小 */
  text-transform: uppercase; /* 文字大写 */
  letter-spacing: 0.05em; /* 字符间距稍微增加 */
  color: #fff; /* 白色文字 */
  background-color: #d9534f; /* 深红色背景，常用于警告或退出按钮 */
  border: none; /* 无边框 */
  border-radius: 4px; /* 圆角边框 */
  cursor: pointer; /* 鼠标悬停时显示手形指针 */
  transition: background-color 0.2s ease-in-out; /* 平滑背景色过渡 */
  outline: none; /* 移除聚焦时的轮廓 */
}

#user-info .logout-button:hover {
  background-color: #c9302c; /* 鼠标悬停时背景色变深 */
}

#user-info .logout-button:disabled {
  background-color: #cccccc; /* 禁用状态的按钮背景色 */
  cursor: not-allowed; /* 禁用状态的鼠标指针 */
}



.image-container {
  position: relative;
  margin-top: 20px;
}


/* 历史任务弹窗 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色遮罩层 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.task-table {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 60%; /* 固定宽度 */
  max-height: 80%; /* 固定高度 */
  overflow: auto; /* 超出内容可滚动 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.btn {
  margin: 0 15px; /* Spacing between buttons */
  padding: 10px 20px; /* 内边距调整，确保按钮看起来更加填充 */
  font-size: 16px; /* 文本大小 */
  color: white; /* 文本颜色 */
  background-color: #3498db; /* 蓝色背景 */
  border: 2px solid #3498db; /* 蓝色边框 */
  border-radius: 5px; /* 圆角边框 */
  cursor: pointer; /* 悬停时显示手形光标 */
  transition: background-color 0.3s; /* 背景颜色变化的过渡效果 */
  outline: none; /* 移除聚焦时的边框 */
}

.btn:hover {
  background-color: #2980b9; /* 悬停时背景色变深 */
}

</style>
