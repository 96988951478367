<template>
  <div class="container">
    <div class="form-group">
      <label for="task-id">任务ID：</label>
      <div id="task-id" class="plain-text">{{ taskId }}</div>
    </div>
    <div class="form-group">
      <label for="source-type">信息来源：</label>
      <select id="source-type" v-model="sourceType">
        <option value="url">URL链接</option>
        <option value="image">上传图片</option>
      </select>
    </div>
    <div class="form-group" v-if="sourceType === 'url'">
      <label for="message-source">URL链接：</label>
      <input type="text" id="message-source" v-model="messageSource" placeholder="输入URL链接" />
    </div>
    <div class="form-group" v-if="sourceType === 'image'">
      <label for="file-upload">上传图片：</label>
      <div class="upload-section">
        <input type="file" id="file-upload" @change="handleFileUpload" />
      </div>
      <div class="image-preview-container" v-if="filePreview">
        <img :src="filePreview" alt="Image Preview" class="image-preview" />
      </div>
    </div>
    <div class="form-group">
      <label></label> <!-- Empty label to align the button -->
      <button @click="startRecognition" :disabled="isLoading">
        <span v-if="isLoading" class="spinner"></span>
        <span v-if="!isLoading">开始识别</span>
      </button>
    </div>
    <div class="form-group">
      <label for="recognition-result">识别结果：</label>
      <textarea id="recognition-result" v-model="recognitionResult"></textarea>
    </div>
    <div class="form-group">
      <label></label> <!-- Empty label to align the buttons -->
      <button @click="copyText">复制文案</button>
      <button @click="navigateToTravelTwo">文案优化</button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import apiService from '@/api/apiService.js';

export default {
  data() {
    return {
      taskId: uuidv4(),
      sourceType: 'url', // Default to URL
      messageSource: '',
      recognitionResult: '',
      fileUploaded: false, // Track if a file is uploaded
      filePreview: null, // Store the file preview URL
      selectedFile: null, // Store the selected file
      isLoading: false, // Track if the recognition is in progress
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileUploaded = true; // Set fileUploaded to true when a file is uploaded
        this.filePreview = URL.createObjectURL(file); // Create a preview URL for the uploaded file
        this.selectedFile = file; // Store the selected file
        console.log('File uploaded:', file);
      } else {
        this.fileUploaded = false; // Set fileUploaded to false if no file is uploaded
        this.filePreview = null;
        this.selectedFile = null;
      }
    },
    async startRecognition() {
      this.isLoading = true; // Set loading state to true when the recognition starts
      if (this.sourceType === 'url') {
        // Handle URL recognition
        try {
          const requestCnc = {
            url: this.messageSource,
          };
          const response = await apiService.getUrl(requestCnc);
          this.recognitionResult = response.data.data.content;
          console.log(response.data.data.content);
        } catch (error) {
          console.error('Error during recognition:', error);
        } finally {
          this.isLoading = false; // Set loading state to false when the recognition ends
        }
      } else if (this.sourceType === 'image') {
        // Handle image recognition
        if (!this.selectedFile) {
          alert('Please select a file first.');
          this.isLoading = false; // Set loading state to false if no file is selected
          return;
        }

        try {
          const response = await apiService.uploadImageToOCR(this.selectedFile);
          this.recognitionResult = response.data.msg; // Update the recognition result
        } catch (error) {
          console.error('Error during image upload:', error);
        } finally {
          this.isLoading = false; // Set loading state to false when the recognition ends
        }
      }
    },
    copyText() {
      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      // Set its value to the recognition result
      textarea.value = this.recognitionResult;
      // Append it to the body
      document.body.appendChild(textarea);
      // Focus and select the text
      textarea.focus();
      textarea.select();
      try {
        // Copy the selected text
        document.execCommand('copy');
        console.log('Text copied to clipboard');
        alert('文案已复制到剪切板');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
      // Remove the temporary textarea
      document.body.removeChild(textarea);
    },
    navigateToTravelTwo() {
      this.$router.push({ 
        name: 'TravelTwo', 
        params: { recognitionResult: this.recognitionResult } 
      });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.upload-section {
  display: flex;
  align-items: center;
}
.image-preview-container {
  margin-top: 10px; /* Space between upload button and preview */
}
label {
  margin-right: 10px;
  width: 150px; /* Adjust width as needed */
}
input[type="text"],
textarea,
select {
  flex: 1;
  padding: 10px;
}
.plain-text {
  padding: 10px;
  background-color: transparent;
  border: none;
}
button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  margin-bottom: 10px; /* Space between buttons */
}
button:disabled {
  background-color: #ccc; /* Disabled button background color */
  cursor: not-allowed; /* Not-allowed cursor on hover */
}
.spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
input[type="file"] {
  margin-left: 10px;
}
.image-preview {
  max-height: 100px;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #f9f9f9;
}
</style>
