<template>
  <div class="ai-news-container">
    <!-- <div class="ai-news-top-left">
      <el-button type="primary" plain @click="goToAiNews">AI资讯</el-button>
      <el-button type="primary" plain @click="showQRCodeModal">扫描加AI交流群</el-button>
      <el-button type="primary" plain @click="fetchData">Prompt词典</el-button>
      <hr /> 
    </div> -->
    <!-- <el-button type="primary" plain class="back-button" @click="goBack">返回</el-button> -->

    <div class="navigation-bar">
      <div class="nav-item" @click="fetchAiNews()">全部</div>
      <div class="nav-item" v-for="item in navItems" :key="item.id" @click="getNewListByLabelId(item.labelId)">{{ item.newsLabel }}</div>
    </div>
    <div v-for="newsItem in newsList" :key="newsItem.id" class="news-item">
      <div class="news-content-block">
        <!-- 新闻标题和摘要 -->
        <div class="news-title-block">
          <h2 class="news-title">{{ newsItem.newsTitle }}</h2>
          <time class="news-time">{{ newsItem.createTime | formatDate }}</time>
        </div>
        <div class="news-summary">
          <!-- <p class="news-summary-content">{{ newsItem.newsContent }}</p> -->
          <p class="news-summary-content" v-html="newsItem.newsContent"></p>
          <!-- <input type="text" class="news-input" 
            v-if="newsItem.labelId && newsItem.gptComment" 
            :placeholder="`评论 ${newsItem.gptComment}`" 
            readonly> -->
            <textarea class="news-textarea" 
            v-if="newsItem.labelId && newsItem.gptComment" 
            :placeholder="`评论： ${newsItem.gptComment}`" 
            readonly>
            </textarea>
        </div>
        <!-- 新闻图片 -->
        <div class="news-image">
          <img :src="newsItem.newsImageUrl" :alt="newsItem.title" />
        </div>
      </div>
      <!-- 分割线 -->
      <hr class="news-divider">
    </div>
  </div>
</template>

<script>
import api from '@/api/apiService.js';
export default {
  data() {
    return {
      newsList: [], // 资讯列表初始化为空数组
      navItems: [], // 用于存储导航项的数组
    };
  },
  created() {
    this.fetchAiNews();
    this.getLabel();
  },
  methods: {
    getNewListByLabelId(id) { 
      api.getLabelListByLabelId(id).then(response => {
          // 假设后端返回的数据结构是 { data: { imageUrl: '...', prompt: '...' } }
          this.newsList = response.data.data; // 假设后端直接返回资讯列表
        })
        .catch(error => {
          console.error('Error fetching image details:', error);
          // 这里你可以处理错误，比如显示一个错误信息
        });
    },
    async fetchAiNews() {
      try {
        const response = await api.getAiNews();
        this.newsList = response.data.data; // 假设后端直接返回资讯列表
      } catch (error) {
        console.error('Error fetching AI news:', error);
      }
    },
    async getLabel() {
      try {
        const response = await api.getLabelList();
        this.navItems = response.data.rows; // 假设后端直接返回资讯列表
      } catch (error) {
        console.error('Error fetching AI Label:', error);
      }
    },
    goBack() {
      // 使用 router 的 go 方法返回上一页
      this.$router.go(-1);
      // 或者使用 push 方法返回主页
      // this.$router.push('/');
    }
  }
};
</script>

<style>

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  /* 其他样式 */
}

.news-content-block {
  display: flex; /* 启用 flex 布局 */
  align-items: flex-start; /* 子元素顶部对齐 */
}

.news-title-block, .news-summary, .news-image {
  flex: 1; /* 每个子元素都平分可用空间 */
  padding: 0 10px; /* 为子元素添加一些内边距 */
}

.news-image img {
  max-width: 100%; /* 限制图片宽度不超过其容器 */
  height: 240px; /* 高度自动调整以保持图片比例 */
}

.news-divider {
  border: none;
  height: 1px;
  background-color: gray; /* 设置分割线颜色 */
  width: 100%; /* 宽度填满容器 */
  margin-top: 10px; /* 分割线与内容之间的间距 */
}

.news-title-block {
  display: flex; /* 使用 flex 布局 */
  flex-direction: column; /* 子元素垂直排列 */
  justify-content: space-between; /* 分配子元素间的空间 */
  height: 100%; /* 设置为父容器的高度，确保 news-time 可以置底 */
}

.news-summary-content{
  text-align: left;
}

.news-title {
  font-size: 37px; /* 设置标题字体大小 */
  text-align: left; /* 标题文本左对齐 */
  margin: 0 0 10px 0; /* 标题的外边距，可根据需要调整 */
}
.news-time {
  margin-top: auto; /* 将时间推到底部 */
  text-align: left; /* 标题文本左对齐 */
  width: 100%; /* 时间宽度设置为与其容器一样宽 */
}
.news-item {
  margin-left: 20px; /* 左边距为 20px */
  margin-right: 20px; /* 右边距为 20px */
  margin: 0 20px; /* 上下边距为 0，左右边距为 20px */
  /* ... 其他样式 ... */
}

/*标签 */

.navigation-bar {
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px; /* 根据您的设计需求调整 */
  background-color: #f8f9fa; /* 示例背景颜色 */
  border-bottom: 1px solid #dcdcdc; /* 底部边框线 */
}

.nav-item {
  margin-right: 20px; /* 导航项之间的间距 */
  color: #333; /* 文字颜色 */
  font-size: 16px; /* 文字大小 */
  cursor: pointer; /* 鼠标悬停时的指针形状 */
  padding: 5px 10px; /* 文字周围的内边距 */
}

.nav-item:hover {
  color: #000; /* 鼠标悬停时文字颜色变化 */
  text-decoration: underline; /* 鼠标悬停时下划线 */
}

/**评论文本框 */
.news-input {
  width: 100%; /* 文本框宽度设置为填满父容器 */
  padding: 8px; /* 文本框内边距 */
  margin-top: 10px; /* 与新闻内容之间的间距 */
  border-radius: 4px; /* 边框圆角 */
  background-color: #e9ecef; /* 只读文本框的背景色 */
  color: #495057; /* 文本颜色 */
  cursor: not-allowed; /* 鼠标悬停时显示不允许编辑的光标 */
  background-color: rgba(128, 128, 128, 0.3); 
  border: none; /* 无边框 */
}

.news-textarea {
  font-weight: bold; /* 设置文本为加粗 */
  padding: 10px; /* 内边距 */
  margin: 5px; /* 外边距，如果需要 */
  box-sizing: border-box; /* 确保内边距和边框宽度包含在宽度内 */
  resize: none; /* 禁止调整大小 */
  background-color: #f0f0f0; /* 灰色背景 */
  border: none; /* 无边框 */
  width: 100%; /* 宽度 */
  height: auto; /* 高度自动 */
  overflow: auto; /* 如果内容超出则显示滚动条 */
  white-space: pre-wrap; /* 保持空白符并自动换行 */
  word-wrap: break-word; /* 在长单词或 URL 地址处自动换行 */
  font-size: 16px;
}





.news-input:read-only {
  border-color: #ced4da; /* 只读状态下的边框颜色 */
}


</style>
