<template>
  <div id="app">
    <div class="ai-news-top-left">
      <router-link to="/" class="nav-item" tag="el-button" type="primary" plain>Prompt词典</router-link>
      <router-link to="/AiNews" class="nav-item" tag="el-button" type="primary" plain>AI资讯</router-link>
      <el-button plain @click="showQRCodeDialog = true">扫描加AI交流群</el-button>
      <router-link to="/ComfyUi" class="nav-item" tag="el-button" type="primary" plain>室内设计</router-link>
      <router-link to="/SdAi" class="nav-item" tag="el-button" type="primary" plain>Sd3文生图</router-link>
      <router-link to="/ComfyUiNt" class="nav-item" tag="el-button" type="primary" plain>粘土滤镜</router-link>
      <!-- <router-link to="/InboundTravel" class="nav-item" tag="el-button" type="primary" plain>InboundTravel</router-link>
      <router-link to="/TravelTwo" class="nav-item" tag="el-button" type="primary" plain>TravelTwo</router-link> -->
    </div>

    <!-- 模态框 -->
    <el-dialog :visible.sync="showQRCodeDialog" width="30%" center>
      <img src="https://cdn.aimaginate.chat/10/2023/11/aimaginate_20231122171953A008.png" alt="二维码图片" class="qr-code">
      <div style="text-align: center; margin-top: 10px; font-size: 37px;">扫码添加AI交流群</div>
    </el-dialog>
    <!-- 路由内容将在这里渲染 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showQRCodeDialog: false // 控制模态框显示的变量
    };
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.ai-news-top-left {
  display: flex;
  flex-wrap: wrap; /* 让按钮自动换行 */
  justify-content: center;
  gap: 0px; /* 按钮之间的间距 */
  margin-bottom: 10px; /* 与下方内容的间距 */
}

.nav-item .el-button,
.el-button {
  width: 140px; /* 固定宽度 */
  height: 35px; /* 固定高度 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.2; /* 使文本换行 */
  white-space: normal; /* 允许文本换行 */
}

.qr-code {
  width: 100%; /* 设置图片宽度为容器的100% */
  height: auto; /* 设置图片高度自动 */
  margin-bottom: 10px; /* 图片下方的间距 */
}
</style>