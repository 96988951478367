// src/store.js
import Vue from 'vue';
import Vuex from 'vuex';
import { setCookie, getCookie, eraseCookie } from '@/utils/cookieUtils';  // 确保正确的路径

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isAuthenticated: !!localStorage.getItem('authToken') || !!getCookie('authToken'),  // 检查 localStorage 和 Cookie
        authToken: localStorage.getItem('authToken') || getCookie('authToken'),  // 从 localStorage 或 Cookie 获取 token
        isLoading: false,  // 添加isLoading状态
        error: null  // 添加错误状态
    },
    mutations: {
        setAuthentication(state, status) {
            state.isAuthenticated = status;
        },
        setAuthToken(state, token) {
            state.authToken = token;
        },
        setLoading(state, isLoading) {  // 设置加载状态
            state.isLoading = isLoading;
        },
        setError(state, error) {  // 设置错误信息
            state.error = error;
        },
        setAuthStatus(state, status) {
            state.isAuthenticated = status;
        }
    },
    actions: {
        authenticateUser({ commit }, { status, token }) {
            commit('setLoading', true);  // 启动加载状态
            commit('setAuthentication', status);
            if (status) {
                commit('setAuthToken', token);
                localStorage.setItem('authToken', token);  // 存储 token 到 localStorage
                setCookie('authToken', token, 7);  // 存储 token 到 Cookie
            } else {
                commit('setAuthToken', null);
                localStorage.removeItem('authToken');  // 清除 localStorage 中的 token
                eraseCookie('authToken');  // 清除 Cookie 中的 token
            }
            commit('setLoading', false);  // 关闭加载状态
        }
    },
    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        authToken(state) {
            return state.authToken;
        },
        isLoading(state) {  // 获取加载状态
            return state.isLoading;
        }
    }
});
