<template>
  <div class="container">
    <div class="form-group">
      <label for="task-id">任务ID：</label>
      <div id="task-id" class="plain-text">{{ taskId }}</div>
    </div>
    <div class="form-group">
      <label for="original-text">原始文案：</label>
      <textarea id="original-text" v-model="originalText"></textarea>
    </div>
    <div class="form-group">
      <label for="optimization-model">优化模型：</label>
      <select id="optimization-model" v-model="optimizationModel">
        <option value="kimi">kimi</option>
        <option value="chatgpt">chatgpt</option>
        <!-- Add other options here -->
      </select>
    </div>
    <div class="form-group">
      <label for="translation-text">翻译文案 (非必填)：</label>
      <select id="translation-text" v-model="translationText">
        <option value="英语">英语</option>
        <option value="中文">中文</option>
        <option value="泰语">泰语（Kimi暂不支持，建议更换模型）</option>
        <option value="韩语">韩语</option>
        <!-- Add other options here -->
      </select>
    </div>
    <div class="form-group">
      <label for="reference-style">参考风格 (非必填)：</label>
      <select id="reference-style" v-model="referenceStyle">
        <option value="小红书">小红书</option>
        <option value="instagram">Instagram</option>
        <option value="facebook帖子">Facebook帖子</option>
        <!-- Add other options here -->
      </select>
    </div>
    <div class="form-group">
      <label for="word-limit-max">字数上限 (非必填)：</label>
      <input type="number" id="word-limit-max" v-model="wordLimitMax" placeholder="仅能输入数字" />
    </div>
    <div class="form-group">
      <label for="word-limit-min">字数下限 (非必填)：</label>
      <input type="number" id="word-limit-min" v-model="wordLimitMin" placeholder="仅能输入数字" />
    </div>
    <div class="form-group">
      <label for="optimization-result">优化结果：</label>
      <textarea id="optimization-result" v-model="optimizationResult"></textarea>
    </div>
    <button @click="optimizeText" :disabled="isLoading">
      <span v-if="isLoading" class="spinner"></span>
      <span v-if="!isLoading">开始优化</span>
    </button>
    <button @click="copyText">复制文案</button>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/apiService.js';

export default {
  props: {
    recognitionResult: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      taskId: uuidv4(),
      originalText: this.recognitionResult,
      optimizationModel: 'kimi',
      translationText: '中文',
      referenceStyle: '小红书',
      wordLimitMax: '',
      wordLimitMin: '',
      optimizationResult: '',
      isLoading: false, // Track if the optimization is in progress
    };
  },
  methods: {
    async optimizeText() {
      this.isLoading = true; // Set loading state to true when the optimization starts
      try {
        const response = await api.optimizeContext({
          messageContent: this.originalText,
          model: this.optimizationModel,
          language: this.translationText,
          style: this.referenceStyle,
          upNum: this.wordLimitMax,
          downNum: this.wordLimitMin,
        });
        this.optimizationResult = response.data.msg;
      } catch (error) {
        console.error('Error during optimization:', error);
      } finally {
        this.isLoading = false; // Set loading state to false when the optimization ends
      }
    },
    copyText() {
      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      // Set its value to the optimization result
      textarea.value = this.optimizationResult;
      // Append it to the body
      document.body.appendChild(textarea);
      // Focus and select the text
      textarea.focus();
      textarea.select();
      try {
        // Copy the selected text
        document.execCommand('copy');
        console.log('Text copied to clipboard');
        alert('文案已复制到剪切板');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
      // Remove the temporary textarea
      document.body.removeChild(textarea);
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
label {
  margin-right: 10px;
  width: 150px; /* Adjust width as needed */
}
input[type="text"],
input[type="number"],
textarea,
select {
  flex: 1;
  padding: 10px;
}
.plain-text {
  /* flex: 1; */
  padding: 10px;
  background-color: transparent;
  border: none;
}
button {
  margin-top: 20px;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff; /* 按钮背景色 */
  color: white; /* 文字颜色 */
  border: none; /* 无边框 */
  border-radius: 4px; /* 边框圆角 */
  cursor: pointer; /* 鼠标悬停时显示手形指针 */
  margin-bottom: 10px; /* 按钮与下一个元素的间隔 */
}
button:disabled {
  background-color: #ccc; /* Disabled button background color */
  cursor: not-allowed; /* Not-allowed cursor on hover */
}
.spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
