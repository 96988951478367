<template>
  <div id="app">
    <!-- <div class="ai-news-top-left">
      <el-button type="primary" plain @click="goToAiNews">AI资讯</el-button>
      <el-button type="primary" plain @click="showQRCodeModal">扫描加AI交流群</el-button>
      <el-button type="primary" plain @click="fetchData">Prompt词典</el-button>
      <hr /> 
    </div> -->
    <el-row type="flex" justify="center" class="header-row">
      <!-- 搜索栏 -->
      <el-col :span="8" class="search-col">
        <el-input
          v-model="searchPrompt"
          placeholder="请输入搜索内容"
          @keyup.enter.native="search"
        ></el-input>
        <el-button icon="el-icon-search" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <!-- 横向菜单 -->
    <div class="menu-container">
      <el-menu :default-active="activeCategory" mode="horizontal" @select="handleSelect">
        <el-menu-item v-for="item in categories" :key="item.category" :index="item.category">{{ item.category }}</el-menu-item>
      </el-menu>
    </div>
    
    <!-- 图片内容区 -->
    <el-row :gutter="20">
    <el-col :span="8" v-for="creative in currentCreativeList" :key="creative.id">
      <el-card class="card-container"  @click.native="goToImageDetails(creative.id)">
        <img :src="creative.imageUrl" class="image-thumbnail" :alt="creative.prompt" />
        <div style="padding: 14px;">
          <span class="two-line-ellipsis">{{ creative.prompt }}</span>
        </div>
      </el-card>
    </el-col>
  </el-row>


  <!-- 弹出框 -->
    <div v-if="isQRCodeModalVisible" class="modal">
      <div class="modal-content">   
        <span class="close" @click="isQRCodeModalVisible = false">&times;</span> 
        <img src="path_to_your_qrcode_image.jpg" alt="QR Code">
        <p>扫码入群</p>
      </div>
    </div>   
  </div>
</template>

<script>
import api from '@/api/apiService.js';
export default {
  data() {
    return {
      categories: [],
      activeCategory: '',
      currentCreativeList: [],
      searchPrompt: '', // 绑定搜索框的数据
      creativeInspiration: {
        prompt: '',
      },
      isQRCodeModalVisible: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    showQRCodeModal() {
      this.isQRCodeModalVisible = true;
    },
    goToAiNews() {
      this.$router.push({ name: 'ai-news' });
    },
    async fetchData() {
      try {
        // const response = await axios.get('http://localhost:8090/creative/inspiration/getList');
        const response = await api.getList();
        this.categories = response.data.data; // 注意这里获取的是 response.data.data
        if (this.categories.length > 0) {
          this.activeCategory = this.categories[0].category;
          this.currentCreativeList = this.categories[0].creativeInspirationList;
        }
      } catch (error) {
        console.error('There was an error:', error.response);
      }
    },
    handleSelect(index) {
      this.activeCategory = index;
      const selectedCategory = this.categories.find(category => category.category === index);
      if (selectedCategory) {
        this.currentCreativeList = selectedCategory.creativeInspirationList;
      } else {
        this.currentCreativeList = [];
      }
    },
    goToImageDetails(imageId) {
      if (this.$route.params.id !== imageId) {
        this.$router.push({ name: 'image-details', params: { id: imageId } }).catch(err => {
          // 忽略 NavigationDuplicated 错误
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    async search() {
      try {
        const creativeInspiration = {
          prompt: this.searchPrompt, // this.searchPrompt 是绑定到搜索框的数据
        };
        // 发送 POST 请求
        // const response = await axios.post('http://localhost:8090/creative/inspiration/search', creativeInspiration); 
        const response = await api.search(creativeInspiration);
        // 处理搜索结果
        // 假设后端返回的数据结构和你获取分类列表时相同
        this.currentCreativeList = response.data.data;
      } catch (error) {
        console.error('搜索错误:', error);
      }
    },
  },
};
</script>

<style>

.card-container {
  height: 400px; /* 你可以根据需要调整这个高度 */
  display: flex;
  flex-direction: column;
}

.image-thumbnail {
  width: 100%;
  height: 300px; /* 图片高度 */
  object-fit: cover;
}

.menu-container {
  display: flex;
  justify-content: center; /* 水平居中 */
}
.search-col {
  display: flex;
  justify-content: flex-end; /* 搜索框向右对齐 */
  align-items: center;
}

.ai-news-top-left {
  position: fixed; /* 固定位置 */
  top: 10px; /* 距顶部的距离 */
  left: 10px; /* 距右边的距离 */
  z-index: 1000; /* 确保在最上层 */
}


.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* 调整为所需宽度 */
  text-align: center;
}

.modal-content img {
  width: 80%; /* 调整图片大小 */
  margin-bottom: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/** prompt显示 */
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

</style>
